import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Typography } from '@mui/material';
import { getUser } from '../../services/AuthService';

interface MobileMenuProps {
  anchorElUser: any;
  toggleDrawer: (isOpen: boolean) => void;
}

export function DefaultMenu({ anchorElUser, toggleDrawer }: MobileMenuProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    getUser().then((response) => {
      if (response.success) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
  })

  }, []);

  const menuItems = [
    { title: 'Home', path: '/' },
    { title: 'Account', path: '/account' },
    { title: 'Bookings', path: '/bookings' },
    { title: 'T&Cs', path: '/T&Cs' },
  ];

  const handleNavigation = (path: string) => {
    toggleDrawer(false)
    navigate(path)
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={toggleDrawer}
    >
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={() => handleNavigation(item.path)}>
          <Typography textAlign="center">{item.title}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}