import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center', mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        404 Page Not Found
      </Typography>
      <Typography variant="subtitle1">
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate('/')}>
        Go to Home
      </Button>
    </Box>
  );
};
