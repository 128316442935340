import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Box, Grid, Dialog, DialogContent, IconButton } from '@mui/material';
import { GridContainer, GridItem } from '../cards/Grid';
import AddIcon from '@mui/icons-material/Add';
import { ErrorSnackbar, SuccessSnackbar } from '../../hooks/SuccessError';
import { getUser } from '../../services/AuthService';
import { createListRequest } from '../../services/MessageService';
import { Message } from '../../types';

export const ListRequest: React.FC = () => {
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openListRequest, setOpenListRequest] = useState(false);
  const [listRequest, setListRequest] = useState<Message>({
    userID:'',
    email: '',
    subject:'Request to list',
    message: '',
  })

  useEffect(() => {
    const getUserDetails = async () => {
      const response = await getUser();
      if (response.success) {
        setListRequest((prevDetails) => ({
          ...prevDetails,
          userID: response.data.uid,
          email: response.data.email,
        }));
      }
    };
    getUserDetails();
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value as string;
    setListRequest((prevItem) => ({ ...prevItem, email: newEmail }));
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value as string;
    setListRequest((prevItem) => ({ ...prevItem, message: newMessage }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const request = await createListRequest(listRequest)
      setOpenSuccessSnackbar(true)
      setOpenListRequest(false)
    } catch (error) {
      setOpenErrorSnackbar(true);
    }
  };

  const handleopenListRequest = () => {
    setOpenListRequest(true);
  };

  const handleCloseJoin = () => {
    setOpenListRequest(false);
  };

  return (
    <React.Fragment>
      <GridItem>
        <IconButton color="primary" aria-label="add"  onClick={handleopenListRequest}  sx={{ position: 'fixed', bottom: 12, left: 16 }}>
          <AddIcon fontSize="large" color='primary' />
        </IconButton>
      </GridItem>

      <Dialog open={openListRequest} onClose={handleCloseJoin} fullWidth>
        <DialogContent>
          <GridContainer>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4" component="div" align="center" color="primary">
                list your surfboards?
              </Typography>
              <Typography gutterBottom variant="body1" color="text.secondary" align="center" sx={{ marginBottom: '2rem' }}>
                Register your interest and we will contact you with further information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
              >
                <TextField
                  label="Your email"
                  variant="outlined"
                  fullWidth
                  required
                  value={listRequest.email}
                  onChange={handleEmailChange}
                />
                <TextField
                  label="Message (optional)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={listRequest.message}
                  onChange={handleMessageChange}
                />
                <Button type="submit" variant="contained" fullWidth color="primary" sx={{ color: 'white', textTransform: 'none', padding: '0.75rem' }}>
                  Request to List
                </Button>
              </Box>
            </Grid>
          </GridContainer>
        </DialogContent>
      </Dialog>
      
      <SuccessSnackbar open={openSuccessSnackbar} message="Request successfully submitted! We'll get in touch with you soon." />
      <ErrorSnackbar open={openErrorSnackbar} message='Failed to send request. Please try again.' />
    </React.Fragment>
  );
};
