import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';


interface ConfProps {
  action: () => void;
  message: string;
  open: boolean;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfProps> = ({action, message, open, onClose}) => {

  const handleAction = async () => {
    action();
    onClose();
    
  };


  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm {message}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {message}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleAction} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

  );
};
