import React from 'react'
import ReactDOM from 'react-dom/client'
import { Box, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Home } from './pages/Home'
import { theme } from './theme'
import { Account } from './pages/account/accounts'
import { PageNotFound } from './pages/PageNotFound'
import { Header } from './components/layout/Header'
import { Login } from './pages/account/Login';
import { ProtectedRoute } from './routes/protectedRoute'
import { BookingsPage } from './pages/Bookings'
import { TCs } from './pages/TCs'
import { Pickup } from './pages/Pickup'
import { Footer } from './components/layout/Footer'
import { TitleProvider } from './hooks/TitleProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
         <TitleProvider>
            <BrowserRouter>
              <Box display="flex" flexDirection="column" minHeight="100vh">
                <Routes>
                  <Route path="/" element={<Header />}>
                    <Route index element={<Home />} />
                    <Route path="account" element={<ProtectedRoute />}>
                      <Route index element={<Account />} />
                    </Route>                  
                    <Route path="bookings" element={<ProtectedRoute />}>
                      <Route index element={<BookingsPage />} />
                      <Route path=":id" element={<Outlet />}>
                        <Route path="pickup" element={<Pickup />} />
                      </Route>                  
                    </Route>                  
                    <Route path="T&Cs" element={<TCs />} />
                  </Route>
                  <Route path="login" element={<Login />} />              
                  <Route path="*" element={<PageNotFound />} />              
                </Routes>
              </Box>
              <Footer />
            </BrowserRouter>
         </TitleProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

reportWebVitals()
