import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Stack, } from '@mui/material';

interface PrimaryCardProps {
  imageSource?: string;
  title?: string;
  priceHour?: number;
  priceDay?: number;
  locationName?: string;
  distance?: number;
  children?: any;
  sx?: any
  onClick?: () => void;
}

const cardStyle = { 
  padding: '10px', 
  boxShadow: 3, 
  borderRadius: 2
}


export const ItemCard: React.FC<PrimaryCardProps> = ({ imageSource, priceDay, priceHour, locationName, distance, title, sx, onClick, children }) => {
    return (
      <Card onClick={onClick} sx={{ ...cardStyle, ...sx, width: 200 }}>
        { children }
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={imageSource}
            alt={title}
            sx={{ borderRadius: 2 }}
          />
          <CardContent>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: '14px' }}>
                <b>{title}</b> 
              </Typography>
              <Typography sx={{ fontSize: '12px' }} >
                <b>from ${priceHour}/h</b>
              </Typography>
            </Stack>
          </CardContent>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography sx={{ fontSize: '9px' }} >
                  {locationName}
            </Typography>
            {distance && (
              <Typography sx={{ fontSize: '9px' }} >
                    {distance.toFixed(1)} km
              </Typography>
            )}
          </Stack>
          
        </CardActionArea>
      </Card>
    );
};