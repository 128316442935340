import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import { getPickupPictures } from '../../services/BookingsService';


interface ConfProps {
  bookingID: string;
  open: boolean;
  onClose: () => void;
}



export const ReturnDialog: React.FC<ConfProps> = ({bookingID, open, onClose}) => {

  const [pickupPhotos, setPickupPhotos] = useState<string[]>([]);  
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const handleAction = async () => {
    onClose();
  };

  useEffect(() => {
    const fetchPhotos = async () => {
       const pics = await getPickupPictures(bookingID)
       setPickupPhotos(pics.data)
    };

    fetchPhotos();
  }, []);

  const handleOpenPhoto = (photo: string) => setSelectedPhoto(photo);
  const handleClosePhoto = () => setSelectedPhoto(null);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Picked up pictures</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Enter the 4 digit code from the store.
          </DialogContentText>
          <TextField
                  label="Code"
                  variant="outlined"
                  fullWidth
                  required
                  rows={1}
                  inputProps={{ maxLength: 4 }}
                  value={code}
                  onChange={handleCodeReceived}
                /> */}

          <Card title="Check pickup pictures">
            <CardContent>
              <Grid container spacing={2}>
                {pickupPhotos?.map((photo, index) => (
                  <Grid item xs={3} key={index}>
                    <img src={photo} alt={`Uploaded ${index + 1}`} style={{ width: '100%', height: 'auto' }} onClick={() => handleOpenPhoto(photo)} />
                  </Grid>
                ))}
              </Grid>
              <Grid>
                <Dialog open={!!selectedPhoto} onClose={handleClosePhoto}>
                  <DialogContent>
                    {selectedPhoto && <img src={selectedPhoto} alt="Zoomed" style={{ maxWidth: '100%', maxHeight: '90vh' }} />}
                  </DialogContent>
                </Dialog>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

  );
};
