import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, updateEmail, updatePassword, signOut} from 'firebase/auth';
import { auth } from '../firebase';
import { ServiceResponse } from '../types';

export const login = async (email: string, password: string): Promise<ServiceResponse> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return { success: true, message: 'Login successful', data: userCredential.user };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const register = async (email: string, password: string): Promise<ServiceResponse> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return { success: true, message: 'Registration successful', data: userCredential.user };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const logout = async (): Promise<ServiceResponse> => {
  try {
    await signOut(auth);
    return { success: true, message: 'Logout successful' };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const getUser = (): Promise<ServiceResponse> => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve({ success: true, message: 'User is logged in', data: user });
      } else {
        resolve({ success: false, message: 'No user is logged in' });
      }
    });
  });
};


export const updateUserEmail = async (newEmail: string): Promise<ServiceResponse> => {
  const user = auth.currentUser;
  if (user) {
    try {
      await updateEmail(user, newEmail);
      return { success: true, message: 'Email updated successfully', data: user };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  } else {
    return { success: false, message: 'No user is logged in' };
  }
};

export const updateUserPassword = async (newPassword: string): Promise<ServiceResponse> => {
  const user = auth.currentUser;
  if (user) {
    try {
      await updatePassword(user, newPassword);
      return { success: true, message: 'Password updated successfully' };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  } else {
    return { success: false, message: 'No user is logged in' };
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<ServiceResponse> => {
  try {
    await sendPasswordResetEmail(email);
    return { success: true, message: 'Password reset email sent successfully' };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
