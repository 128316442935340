import React, { useEffect, useState } from 'react';
import { CardContent, Button, Typography, ToggleButton, ToggleButtonGroup, TextField, Dialog, DialogTitle, Grid, IconButton, DialogContent, Card, Box, Alert } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import { updatePickupInfo } from '../services/BookingsService';
import { useParams, useNavigate } from 'react-router-dom';
import { GridContainer, GridItem } from '../components/cards/Grid';
import { useTitle } from '../hooks/TitleProvider';
import { SuccessSnackbar } from '../hooks/SuccessError';


interface Photo {
  file: File;
  url: string;
}

export const Pickup: React.FC = () => {
  const { id: bookingId } = useParams<{ id: string }>()  
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
  const [comments, setComments] = useState<string>('');
  const [damageOccurrence, setDamageOccurrence] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setTitle("Pickup");
  })

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files).slice(0, 8 - photos.length).map(file => ({
        file: file,
        url: URL.createObjectURL(file),
      }));
      setPhotos(photos.concat(fileArray));
    }
  };

  const handleRemovePhoto = (url: string) => {
    setPhotos(photos.filter(photo => photo.url !== url));
  };

  const handleOpenPhoto = (photo: Photo) => setSelectedPhoto(photo);
  const handleClosePhoto = () => setSelectedPhoto(null);

  const handleDamageOccurrenceChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setDamageOccurrence(newAlignment === 'yes');
  };
  

  const updateBooking = async () => {
    try {
      if (bookingId && (!damageOccurrence || (damageOccurrence && photos.length > 0))) {
        await updatePickupInfo(bookingId, comments, photos, damageOccurrence);
        console.log('ere')
        setOpenSuccessSnackbar(true);
        navigate('/bookings/');
      } else {
        setErrorMessage('Failed to upload photos. Try again')
      }
    } catch (err) {
      setErrorMessage('Failed to upload photos. Try again')
    }
  }

  return (
    <GridContainer>
      <GridItem>
        {errorMessage && (
          <Alert severity="error" sx={{ width: '95%', mb: 2 }}>{errorMessage}</Alert>
        )}
      </GridItem>
      <GridItem>
        <Card title="Upload Photos of Board Condition">
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Upload detailed and thorough photos of the condition of the board.
            </Typography>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handlePhotoUpload}
            />
            <label htmlFor="raised-button-file">
              <Button component="span" startIcon={<PhotoCameraIcon />}>
                Upload Photos
              </Button>
            </label>
            <Grid container spacing={2}>
              {photos.map((photo, index) => (
                <Grid item xs={3} key={index}>
                  <img src={photo.url} alt={`Uploaded ${index + 1}`} style={{ width: '100%', height: 'auto' }} onClick={() => handleOpenPhoto(photo)} />
                  <IconButton onClick={() => handleOpenPhoto(photo)} size="small">
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton onClick={() => handleRemovePhoto(photo.url)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </GridItem>

      <GridItem>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Existing damage?
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={damageOccurrence ? 'yes' : 'no'}
              exclusive
              onChange={handleDamageOccurrenceChange}
            >
              <ToggleButton value="yes">Yes</ToggleButton>
              <ToggleButton value="no">No</ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
        </Card>
      </GridItem>


      {damageOccurrence && (
        <GridItem>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Damages
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                variant="outlined"
                placeholder=" Describe existing damages here..."
              />
            </CardContent>
          </Card>
        </GridItem>
      )}

      <GridItem>
        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', mt: 2 }}>
          <Button variant="contained" color="primary" sx={{ color: 'white', textTransform: 'none' }} onClick={updateBooking}>Submit</Button>
        </Box>
      </GridItem>
      
      <GridItem>
        <Dialog open={!!selectedPhoto} onClose={handleClosePhoto}>
          <DialogTitle>Zoomed Photo</DialogTitle>
          <DialogContent>
            {selectedPhoto && <img src={selectedPhoto.url} alt="Zoomed" style={{ maxWidth: '100%', maxHeight: '90vh' }} />}
          </DialogContent>
        </Dialog>
      </GridItem>

      <SuccessSnackbar open={openSuccessSnackbar} message="Details received!" />
    </GridContainer>
  );
};


