import React, { useEffect, useState } from 'react';
import { CardMedia, Typography, Chip, Grid, Card, CardContent, Button, Divider, Stack, Box} from '@mui/material';
import dayjs from 'dayjs';
import { Booking, BookingStatus, PaymentStatus, Surfboard } from '../../types';
import { cancelBooking, getBookingItem } from '../../services/BookingsService';
import { ConfirmationDialog } from '../dialog/confirmationDialog';
import { useNavigate } from 'react-router-dom';
import { ReturnDialog } from '../dialog/ReturnDialog';
import PinDropOutlinedIcon from '@mui/icons-material/PinDrop';

interface BookingCardProps {
  booking: Booking;
  onClick?: () => void;
  userId?: string;
  updateBookingStatus?: (bookingId: string, status: BookingStatus) => void;
}

const getBookingStatusColor = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.pending:
      return 'warning';
    case BookingStatus.upcoming:
    case BookingStatus.completed:
      return 'primary';
    case BookingStatus.cancelled:
      return 'error';
    default:
      return 'default';
  }
};

const getPaymentStatusColor = (paymentStatus: PaymentStatus) => {
  switch (paymentStatus) {
    case PaymentStatus.pending:
      return 'warning';
    case PaymentStatus.paid:
      return 'primary';
    case PaymentStatus.cancelled:
      return 'error';
    default:
      return 'default';
  }
};

export const BookingCard: React.FC<BookingCardProps> = ({
  booking,
}) => {
  const navigate = useNavigate();
  const [surfboard, setSurfboard] = useState<Surfboard>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [returnDialog, setReturnDialog] = useState(false);
  
  
  useEffect(() => {
    const fetchItem = async () => {
      try {        
        const item = await getBookingItem(booking.surfboardID);
        setSurfboard(item.data);
      } catch (error) {
        console.error('Error fetching user: ', error);
      }
    };
    fetchItem();
  }, []);
 
  const formattedPickupDate = dayjs(booking.pickupDateTime).format('MMMM D, YYYY h:mm A');
  const formattedReturnDate = dayjs(booking.returnDateTime).format('MMMM D, YYYY h:mm A');


  const openhandleCancelBooking = () => {
    setConfirmationDialogOpen(true);
  };
  const handleCloseConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(false);
  };
  const openhandleReturnDialog = () => {
    setReturnDialog(true);
  };
  const handleCloseReturnDialoggOpen = () => {
    setReturnDialog(false);
  };

  const openGoogleMapsLink = (lat: any, lng: any)  => {
    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, '_blank');
  }

  const handleCancelBooking = async () => {
    try {        
      await cancelBooking(booking.id);
      booking = {
        ...booking,
        status: BookingStatus.cancelled
      };
      window.location.reload();
    } catch (error) {
      console.error('Error fetching user: ', error);
    }
    cancelBooking(booking.id);
  };

  const pickupBooking = () => {
    navigate(`${booking.id}/pickup`, { state: { booking } })
  };

  // const returnBooking = async (code: number | string) => {
  //   try {
  
  //     window.location.reload();
  //   } catch (err) {
  //     console.error('Failed to update status:', err);
  //   }
  // };

  return (
    <Card  sx={{ p: 2, mb: 2}}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <CardMedia
              component="img"
              sx={{ width: 100, height: 100, borderRadius: 2 }}
              image={surfboard?.thumbnail} 
            />
            <CardContent>
              <Stack>
                <Typography variant="body2" color="textSecondary"><b>{surfboard?.title}</b></Typography>
                <Typography variant="body2" color="textSecondary"><b>Pickup:</b> {formattedPickupDate} </Typography>
                <Typography variant="body2" color="textSecondary"><b>Return:</b> {formattedReturnDate} </Typography>
                <Box display="flex" alignItems="center">
                <PinDropOutlinedIcon fontSize='small'/>
               
                <Typography variant="body2" color="textSecondary" onClick={() => openGoogleMapsLink(surfboard?.location.lat, surfboard?.location.lng)}>
                 Let's go surfing - {surfboard?.location.name} 
                 </Typography>
                </Box>
               
                {booking.status !== "upcoming" && booking.status !== "active" && (
                  <Chip
                    label={booking.status}
                    color={getBookingStatusColor(booking.status)}
                    variant="outlined"
                    size='small'
                    sx={{ ml: 1 }}
                  />
                )}
    
              </Stack>
            </CardContent>
        </Grid>

        <Stack>
          <Typography variant="h6">$ {booking.totalBookingCost}</Typography>
          <Chip
                    label={booking.paymentStatus}
                    color={getPaymentStatusColor(booking.paymentStatus)}
                    variant="outlined"
                    size='small'
                    sx={{ ml: 1 }}
                  />
        </Stack>
      </Grid>

      {(booking.status === "upcoming" || booking.status === "active") && (
       <>
           
          {booking.pickupStatus === "pending" && (
              <>
              <Divider sx={{ my: 1 }} />  
                <Grid container justifyContent="space-between" alignItems="center" sx={{p: 0, mb: 0}}>       
                <Button variant="text" color="error" onClick={openhandleCancelBooking}>cancel</Button>       
                <ConfirmationDialog action={handleCancelBooking} message="Cancel" open={confirmationDialogOpen} onClose={handleCloseConfirmationDialogOpen}/>   
                <Button variant="text" color="primary" onClick={pickupBooking}>pickup</Button>
              </Grid>
            </> 
          )}
          {booking.pickupStatus === "pickedUp" && (
            <Grid container justifyContent="flex-end" alignItems="center" sx={{p: 0, mb: 0}}>       
              <Button variant="text" color="warning" onClick={openhandleReturnDialog}>return</Button>
              <ReturnDialog bookingID={booking.id} open={returnDialog} onClose={handleCloseReturnDialoggOpen}/>    
            </Grid>
          )}
       </>
      )}
    </Card>


  );
};
