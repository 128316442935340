import React from 'react';
import { Typography, Paper, Container } from '@mui/material';
import { GridItem } from '../components/cards/Grid';

export const TCs: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <GridItem>
         <Typography gutterBottom variant="h4" component="div" align="center" color="primary">
            Terms & conditions
          </Typography>
      </GridItem>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="body1" paragraph>
          Thank you for using Boro. These terms and conditions of use (T&Cs or Terms) regulate your utilisation of our website(s) and any associated mobile applications (the “Platform”), as well as the use of all services provided by Boro Pty Ltd ACN 647 154 096 through the Platform. These T&Cs constitute a legally binding agreement between you (the “user”) and Boro. In these T&Cs, "we," "us," and "our" refer to Boro.
        </Typography>
        <Typography variant="body1" paragraph>
          These T&Cs are of significant importance, and it is imperative that you read them thoroughly. Kindly contact us with any questions before accessing the Platform. By utilising the Platform, you explicitly acknowledge that you have read and comprehend this document in its entirety and agree to abide by its terms. If you do not consent to the T&Cs, please refrain from using the Platform.
        </Typography>
        <Typography variant="body1" paragraph>
          We retain the right to review and modify any of the T&Cs at our sole discretion. Updates to the T&Cs will be made by revising this page. When updating the T&Cs, we will make reasonable efforts to provide you with notice of the changes. Any modifications to the T&Cs become effective immediately from the date of their publication. Prior to continuing, we strongly recommend that you review the T&Cs each time you access the Site to stay acquainted with the most current version.
        </Typography>
        <Typography variant="body1" paragraph>
          Boro provides a peer-to-peer Service to facilitate surf items (“Items”) sharing. We bring together users that are looking to lease out items that they own (Owners) with users that are seeking to rent out items (Borrowers). Surfboard sharing, hire or rental services are provided by Owners to Borrowers. We may act as an agent of Owners or Borrowers as provided for in these T&Cs.
        </Typography>
      </Paper>
    </Container>
  );
};

