import React, { useState, useEffect } from 'react';
import { Autocomplete, useLoadScript, Libraries } from '@react-google-maps/api';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import './location.css'
import { config } from '../../config';

const libraries: Libraries = ['places'];

interface LocationAutocompleteProps {
  onLocationSelect?: (lat: number | null, lng: number | null, address: string | null) => void;
  oldPlace?: string
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps>= ({ onLocationSelect, oldPlace }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState<string>(oldPlace || '');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleApiKey,
    libraries,
  });

  useEffect(() => {
    setInputValue(oldPlace || '');
  }, [oldPlace]);

  if (loadError) {
    return <div>Error loading</div>;
  }

  if (!isLoaded) {
    return <div>Loading</div>;
  }


  const handlePlaceChanged = () => {
    const location = autocomplete?.getPlace();

    if (location?.geometry && location?.formatted_address) {
      const { lat, lng }: any = location.geometry.location;
      const { formatted_address } = location

      if (onLocationSelect) {
        onLocationSelect(lat(), lng(), formatted_address)
      }
      setInputValue(formatted_address)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value === '' && onLocationSelect) {
      onLocationSelect(null, null, null);
    }
  };

  return (
      <Autocomplete
        onLoad={(autoComplete) => setAutocomplete(autoComplete)}
        onPlaceChanged={handlePlaceChanged}
        restrictions={{ country: 'au' }}
      >
        <Grid>
          <TextField
            fullWidth
            margin="normal"
            label="where?"
            value={inputValue}
            onChange={handleInputChange}
          />
        </Grid>
      </Autocomplete>
  )
}
