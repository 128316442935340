import React, { useEffect, useState } from 'react';
import { GridContainer, GridItem } from '../components/cards/Grid';
import { getUserBookings, updateBookingStatusBasedOnTime } from '../services/BookingsService';
import { Booking } from '../types';
import { Grid, Typography } from '@mui/material';
import { BookingCard } from '../components/cards/BookingCard';
import { getUser } from '../services/AuthService';
import { Contact } from '../components/form/Contact';
import { useTitle } from '../hooks/TitleProvider';


export const BookingsPage: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [userID, setUserID] = useState<string>('');
  const [bookings, setBookings] = useState<Booking[]>([]);
  const { setTitle } = useTitle();


  useEffect(() => {
    setTitle("Bookings");
    const fetchUser = async () => {
      try {
        const user = await getUser();
        setUserID(user.data.uid);
        fetchBookings(user.data.uid);
      } catch (error) {
        console.error('Error fetching user: ', error);
      }
    };

    fetchUser();
  }, []);

  const fetchBookings = async (userID: string) => {
    setLoading(true);
    try {
      const allBookings = await getUserBookings(userID);
      const bookingsList = allBookings.data.map((doc: any) => ({
        ...updateBookingStatusBasedOnTime(doc.data()),
      })) as Booking[]; 
      setBookings(bookingsList);
    } catch (error) {
      console.error('Error fetching bookings: ', error);
    } finally {
      setLoading(false);
    }
  };


  const pendingBookings = bookings.filter(booking => booking.status === 'upcoming');
  const activeBookings = bookings.filter(booking => booking.status === 'active');
  const pastBookings = bookings.filter(booking => booking.status !== 'upcoming' && booking.status !== 'active');



  return (
    <GridContainer>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
    
          <GridContainer>

            {activeBookings.length > 0 && (
              <>
                <GridItem>
                  <Typography variant="h5">Active</Typography>
                </GridItem>
                
                {activeBookings.map((booking: any) => (
                  <GridItem key={booking.id}>
                    <BookingCard booking={booking} />
                  </GridItem>
                ))}
              </>
            )}

            {pendingBookings.length > 0 && (
              <>
                <GridItem>
                  <Typography variant="h5">Upcoming</Typography>
                </GridItem>

                {pendingBookings.map((booking: any) => (
                  <GridItem key={booking.id}>
                    <BookingCard booking={booking} />
                  </GridItem>
                ))}
              </>
            )}

            <GridItem>
              <Typography variant="h5">Past</Typography>
            </GridItem>
            {pastBookings.length > 0 ? (
              pastBookings.map((booking: any) => (
                <GridItem key={booking.id}>
                  <BookingCard booking={booking} />
                </GridItem>
              ))
            ) : (
              <GridItem>
                <Typography>No past bookings.</Typography>
              </GridItem>
            )}

            <GridItem>
              <Contact />
            </GridItem>
        </GridContainer>
      )}
    </GridContainer>
  );
};


