import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Stack, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ItemCard } from '../components/cards/ItemCard';
import { Surfboard, Location } from '../types';
import { Book } from '../components/form/Book';
import { ListRequest } from '../components/form/ListRequest';
import { Footer } from '../components/layout/Footer';
import { GridContainer, GridItem } from '../components/cards/Grid';
import { LocationAutocomplete } from '../components/form/Location';
import { Contact } from '../components/form/Contact';
import { getUser } from '../services/AuthService';
import { getAllSurfboards } from '../services/SurfboardsService';
import { calculateDistance, fetchUserLocation } from '../services/LocationService';
import { useTitle } from '../hooks/TitleProvider';



export const Home: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedSurfboard, setSelectedSurfboard] = useState<Surfboard>();
  const [bookDialogOpen, setBookDialogOpen] = useState(false);
  const [isAuthenticated , setIsAuthenticated ] = useState<boolean>(true);
  const [userID, setUserID] = useState<string>('');
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  // const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [allSurfboards, setAllSurfboards] = useState<Surfboard[]>([]);
  const [filteredSurfboards, setFilteredSurfboards] = useState<Surfboard[]>([]);
  const { setTitle } = useTitle();
  


  useEffect(() => {
    setTitle('')
    const fetchSurfboards = async () => {
      setLoading(true);
      try {
        const response = await getAllSurfboards();
        const surfboardsList = response.data.map( (doc:any) => ({
          ...doc.data(),
        })) as Surfboard[];
        setAllSurfboards(surfboardsList)        
        setFilteredSurfboards(surfboardsList);

      } catch (error) {
        console.error('Error fetching surfboards: ', error);
      } finally {
        setLoading(false);
      }

    };
    fetchSurfboards();

    const fetchUser = async () => {
      try {        
        const user = await getUser();
        setUserID(user.data.uid);
        setIsAuthenticated(user.success)
      } catch (error) {
        setIsAuthenticated(false);
        console.error('Error fetching user: ', error);
      }
    };
    fetchUser();

    const getUserLocation = async () => {
      try {
        const location = await fetchUserLocation();
        setUserLocation(location);
      } catch (error) {
        setUserLocation({ lat: -33.8688, lng: 151.2093 });
      }
    };
    getUserLocation();

  }, []);

  const handleSelectSurfboard = (surfboard: Surfboard) => {
    setSelectedSurfboard(surfboard);
    if (isAuthenticated) {
      setBookDialogOpen(true);
    } else {
      navigate('/login')
    }
  };

  const handleCloseBookDialog = () => {
    setBookDialogOpen(false);
  };


  const handleLocationChange = (lat: number | null, lng: number | null) => {
    if (lat && lng) {
      const newFilteredSurfboards = allSurfboards.map(surfboard => {
          const distance = calculateDistance(lat, lng, surfboard.location.lat, surfboard.location.lng);
          return { ...surfboard, distance };
        })
        .filter(surfboard => surfboard.distance <= 20); // Filter surfboards within 20 km
      setFilteredSurfboards(newFilteredSurfboards);
    } else {
      setFilteredSurfboards(allSurfboards);
     
    }
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
    
      <GridContainer>

          <GridItem>
            <Typography onClick={() => navigate(`/`)} color={'primary'} variant="h2" align="center">
              boro
            </Typography> 
            
            <Typography
              onClick={() => navigate(`/`)}
              color={'primary'}
              align="center"
              gutterBottom
              sx={{ mt: 3, mb: 2 }}
            >
              Rent the perfect surfboard, everywhere you go
            </Typography>
          </GridItem>

          <GridItem>
           <LocationAutocomplete onLocationSelect={(lat,lng) => handleLocationChange(lat,lng)}/>
          </GridItem>

          <GridItem>
            {loading ? (
              <Stack alignItems="center" justifyContent="center" sx={{ height: '50vh' }}>
                <CircularProgress />
              </Stack>
            ) : (
              <Grid container justifyContent="space-between" spacing={1}>
                {filteredSurfboards.map((surfboard: any) => (
                  <Grid item key={surfboard.id}>
                    <ItemCard
                      imageSource={surfboard.thumbnail}
                      priceHour={surfboard.priceHour}
                      priceDay={surfboard.priceDay}
                      locationName={surfboard.location.name}
                      title={surfboard.title}
                      distance={surfboard.distance}
                      onClick={() => handleSelectSurfboard(surfboard)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </GridItem>

          <GridItem>
            <ListRequest />
          </GridItem>

          <GridItem>
            <Contact />
          </GridItem>

      </GridContainer>

      {selectedSurfboard && (
         <Book userID={userID} surfboard={selectedSurfboard} open={bookDialogOpen} onClose={handleCloseBookDialog} />
      )}

    </Box>
  );
};
