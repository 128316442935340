import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Outlet } from 'react-router-dom';
import { DefaultMenu } from './Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import { AppBar, Button, Grid, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getUser } from '../../services/AuthService';
import { useTitle } from '../../hooks/TitleProvider';


export const Header = () => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const navigateBack = () => navigate(-1);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const { title } = useTitle();
   

  useEffect(() => {
  
    getUser().then((response) => {
      if (response.success) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <>
      <AppBar position="sticky" sx={{p:0}}>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              {!isHomePage ? (    
                <IconButton color="primary" aria-label="add"  onClick={navigateBack} >
                  <ArrowBackIcon fontSize="large" color='primary' />
                </IconButton>
              ):(
                <Typography variant="h6" align="left" color="primary">boro</Typography> 
              )}
            </Grid>
            <Grid item>
              <Typography variant="h5" align="center" color="primary">{title}</Typography> 
            </Grid>
            <Grid item>
              {isAuthenticated ? (
                <>
                 <DefaultMenu anchorElUser={anchorElUser} toggleDrawer={handleCloseUserMenu} />
                  <IconButton color="primary" aria-label="add"  onClick={handleOpenUserMenu} >
                      <AccountCircle fontSize="large" color='primary' />
                  </IconButton>
                </>
              ) : (
                <Button onClick={handleLogin} >
                  Log in
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Outlet />
    </>

  );
};
