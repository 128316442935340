import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Box, Grid, Dialog, DialogContent, IconButton } from '@mui/material';
import { GridContainer, GridItem } from '../cards/Grid';
import MessageIcon from '@mui/icons-material/Message';
import { ErrorSnackbar, SuccessSnackbar } from '../../hooks/SuccessError';
import { getUser } from '../../services/AuthService';
import { createMessage } from '../../services/MessageService';
import { Message } from '../../types';

export const Contact: React.FC = () => {
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [message, setMessage] = useState<Message>({
    userID:'',
    email: '',
    subject:'',
    message: '',
  })

  useEffect(() => {
    const getUserDetails = async () => {
      const response = await getUser();
      if (response.success) {
        setMessage((prevDetails) => ({
          ...prevDetails,
          userID: response.data.uid,
          email: response.data.email,
        }));
      }
    };
    getUserDetails();
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value as string;
    setMessage((prevItem) => ({ ...prevItem, email: newEmail }));
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSubject = e.target.value as string;
    setMessage((prevItem) => ({ ...prevItem, subject: newSubject }));
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value as string;
    setMessage((prevItem) => ({ ...prevItem, message: newMessage }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const request = await createMessage(message)
      setOpenSuccessSnackbar(true)
      setOpenSendMessage(false)
    } catch (error) {
      setOpenErrorSnackbar(true);
    }
  };

  const handleOpenSendMessage = () => {
    setOpenSendMessage(true);
  };

  const handleCloseJoin = () => {
    setOpenSendMessage(false);
  };

  return (
    <React.Fragment>
      <GridItem>
        <IconButton color="primary" aria-label="add"  onClick={handleOpenSendMessage}  sx={{ position: 'fixed', bottom: 12, right: 16 }}>
          <MessageIcon fontSize="large" color='primary' />
        </IconButton>
      </GridItem>

      <Dialog open={openSendMessage} onClose={handleCloseJoin} fullWidth>
        <DialogContent>
          <GridContainer>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4" component="div" align="center" color="primary">
                Contact us
              </Typography>
              <Typography gutterBottom variant="body1" color="text.secondary" align="center" sx={{ marginBottom: '2rem' }}>
                Have a question or need assistance? Let us know!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
              >
                <TextField
                  label="Your email"
                  variant="outlined"
                  fullWidth
                  required
                  value={message.email}
                  onChange={handleEmailChange}
                />
                 <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  required
                  value={message.subject}
                  onChange={handleSubjectChange}
                />
                <TextField
                  label="Message (optional)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={message.message}
                  onChange={handleMessageChange}
                />
                <Button type="submit" variant="contained" fullWidth color="primary" sx={{ color: 'white', textTransform: 'none', padding: '0.75rem' }}>
                  Send
                </Button>
              </Box>
            </Grid>
          </GridContainer>
        </DialogContent>
      </Dialog>
      
      <SuccessSnackbar open={openSuccessSnackbar} message="Message received! We'll get in touch with you soon." />
      <ErrorSnackbar open={openErrorSnackbar} message='Failed to send message. Please try again.' />
    </React.Fragment>
  );
};
