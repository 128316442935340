import React from 'react';
import { Grid } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface DateTimeRangeProps {
  dateTimeRange: {
    pickupDateTime: Dayjs;
    returnDateTime: Dayjs;
  };
  setDateTimeRange: React.Dispatch<React.SetStateAction<{
    pickupDateTime: Dayjs;
    returnDateTime: Dayjs;
  }>>;
}

export function DateTimeRange({ dateTimeRange, setDateTimeRange }: DateTimeRangeProps) {
  const handlePickupChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setDateTimeRange((prev) => ({ ...prev, pickupDateTime: newValue }));
      // Ensure return date is at least one hour after the new pickup date
      if (dateTimeRange.returnDateTime && newValue.add(1, 'hour').isAfter(dateTimeRange.returnDateTime)) {
        setDateTimeRange((prev) => ({ ...prev, returnDateTime: newValue.add(1, 'hour') }));
      }
    }
  };

  const handleReturnChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setDateTimeRange((prev) => ({ ...prev, returnDateTime: newValue }));
    }
  };

  const disableReturnDate = (date: Dayjs) => {
    // Disable past dates and ensure the return date is at least one hour after the pickup date
    return date.isBefore(dateTimeRange.pickupDateTime.add(1, 'hour'), 'minute');
  };

  const disablePickupDate = (date: Dayjs) => {
    // Disable past dates
    return date.isBefore(dayjs(), 'minute');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            sx={{ width: '100%' }}
            label="Pickup"
            value={dateTimeRange.pickupDateTime}
            onChange={handlePickupChange}
            ampm={false}
            minDateTime={dayjs()}
            shouldDisableDate={disablePickupDate}
            minutesStep={30}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            sx={{ width: '100%' }}
            label="Return"
            value={dateTimeRange.returnDateTime}
            onChange={handleReturnChange}
            ampm={false}
            minDateTime={dateTimeRange.pickupDateTime.add(1, 'hour')}
            shouldDisableDate={disableReturnDate}
            minutesStep={30}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}