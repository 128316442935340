import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUser } from '../services/AuthService';

export const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUser().then((response) => {
      if (response.success) {
        setIsLoading(false)
        setIsAuthenticated(true);
      } else {
        setIsLoading(false)
        setIsAuthenticated(false);
      }
  })
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};
