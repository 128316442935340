import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { GridContainer, GridItem } from '../../components/cards/Grid';
import { login, register, sendPasswordResetEmail } from '../../services/AuthService';
import { SuccessError } from '../../hooks/SuccessError';

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [successErrorOpen, setSuccessErrorOpen] = useState(false);
  const [successErrorSeverity, setSuccessErrorSeverity] =useState<any>('');
  const [successErrorMessage, setSuccessErrorMessage] = useState('');


  const handleLogin = async () => {
    try {
      const response = await login(email, password)
      if (response.success) {
        navigate('/');
        setSuccessErrorSeverity('success')  
      } else {
        setSuccessErrorSeverity('error')
      }
      setSuccessErrorOpen(true)
      setSuccessErrorMessage(response.message)
    } catch (error: any) {
      setSuccessErrorOpen(true)
      setSuccessErrorSeverity('error')
      setSuccessErrorMessage(error.message)
    }
  };

  const handleRegister = async () => {
    try {
      const response = await register(email, password);
      if (response.success) {
        navigate('/');
        setSuccessErrorSeverity('success')  
      } else {
        setSuccessErrorSeverity('error')
      }
      setSuccessErrorOpen(true)
      setSuccessErrorMessage(response.message)
    } catch (error: any) {
      setSuccessErrorOpen(true)
      setSuccessErrorSeverity('error')
      setSuccessErrorMessage(error.message)
    }
  };

  const handleForgotPassword = async() => {
    if(!email) {
      setSuccessErrorOpen(true)
      setSuccessErrorSeverity('error')
      setSuccessErrorMessage("Please enter your email")
    } else {
      try {
        const response = await sendPasswordResetEmail(email);
        if (response.success) {
          setSuccessErrorSeverity('success')  
        } else {
          setSuccessErrorSeverity('error')
        }
        setSuccessErrorOpen(true)
        setSuccessErrorMessage(response.message)
      } catch (error: any) {
        setSuccessErrorOpen(true)
        setSuccessErrorSeverity('error')
        setSuccessErrorMessage(error.message)
      }
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <GridContainer>
      <GridItem>
        <Typography onClick={() => navigate(`/`)} color={'primary'} variant="h3" align="center" gutterBottom sx={{ mt: 3, mb: 10 }}>
          boro
        </Typography>
      </GridItem>
      <GridItem>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <TextField
          type="email"
          label="Email"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="outlined"
          fullWidth
          required
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" onClick={handleLogin}  color="primary" sx={{ color: 'white', textTransform: 'none' }}> Login </Button>
        <Button  variant="outlined" onClick={handleRegister}  sx={{ textTransform: 'none' }}>Register</Button>
        <Button variant="text" onClick={handleForgotPassword} size="small"  sx={{ fontStyle: 'italic', color: 'grey', textTransform: 'none' }}>Forgot password?</Button>
       
      </Box>
      </GridItem>
      <SuccessError open={successErrorOpen} severity={successErrorSeverity} message={successErrorMessage} />
    </GridContainer>

  );
};
