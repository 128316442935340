export interface Surfboard {
    id: string,
    title: string,
    thumbnail: string,
    pictures: Array<string>,
    priceHour: number,
    priceDay: number,
    addOns: Array<addOn>,
    location: Location,
    ownerID: string,
    nbOfItems: number,
    description: String,
    length: Number,
    volume: Number,
    category: String,
}

export interface Location {
  name?: string,
  lng: number,
  lat: number,
}

export interface Message {
  userID: string,
  email: string,
  subject?: string,
  message: string,
}

export interface ServiceResponse {
  success: boolean;
  message: string;
  data?: any;
}

export interface addOn {
  name: string,
  priceDay: number,
}

export interface Pickup {
  existingDamage: boolean,
  comment?: string,
  pictures?: Array<String>,
}

export interface Booking {
    id: string,
    addOns: Array<addOn>,
    addOnsCost: number,
    amountDays: number,
    amountHours: number,
    itemCost: number,
    totalBookingCost: number,
    paymentStatus: PaymentStatus,
    refCode: number,
    pickupDateTime: string
    returnDateTime: string,
    status: BookingStatus,
    pickupStatus: PickupReturnStatus,
    surfboardID: string,
    userID: string,
    timeStamp: number,
    pickup: Pickup | null ,
    return: number | string 

  }

  export enum BookingStatus {
    pending = 'pending',
    upcoming = 'upcoming',
    cancelled = 'cancelled',
    completed = 'completed',
    active = 'active',
    draft = 'draft'
  }

  export enum PickupReturnStatus {
    pending = 'pending',
    pickedUp = 'pickedUp',
    returned = 'returned',

  }

  export enum PaymentStatus {
    draft = 'draft',
    pending = 'pending',
    paid ='paid',
    cancelled ='cancelled',
    refunded = 'refunded',
  }

  export class UnAuthorisedError extends Error {
    constructor(message?: string) {
      super(message);
      this.name = 'UnAuthorisedError'
    }
  }
  