import { collection, addDoc, getDocs, query, where, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { Booking, BookingStatus, Pickup, PickupReturnStatus, ServiceResponse, UnAuthorisedError } from '../types';
import dayjs from 'dayjs';
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';


interface Photo {
  file: File;
  url: string;
}

export const createBooking = async (booking: Booking): Promise<ServiceResponse> => {
  try {
    const collectionRef = collection(db, 'bookings');
    const docRef = await addDoc(collectionRef, booking);
    await updateDoc(docRef, { id: docRef.id });
    return { success: true, message: 'Booking successfully created'};
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const getUserBookings = async (uid: string): Promise<ServiceResponse> => {
  try {
    const q = query(collection(db, "bookings"), where("userID", "==", uid));
    const querySnapshot = await getDocs(q);
    return { success: true, message: 'Bookings successfuly',  data: querySnapshot.docs };
  
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const getBookingItem = async (itemID: string): Promise<ServiceResponse> => {
    try {
      const docRef = doc(db, "surfboards", itemID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { success: true, message: 'Bookings successfuly',  data: docSnap.data() };
      } else {
        return { success: false, message: "No such item" };
      }

    } catch (error: any) {
      return { success: false, message: error.message };
    }
};

export const updateBookingStatusBasedOnTime = (booking: Booking) => {
  const currentTime = dayjs();
  const pickupDateTime = dayjs(booking.pickupDateTime);
  const returnDateTime = dayjs(booking.returnDateTime);

  if (currentTime.isAfter(pickupDateTime) && currentTime.isBefore(returnDateTime)) {
    if(booking.pickupStatus !== PickupReturnStatus.returned) {
      booking.status = BookingStatus.active;
    }
  } else if (currentTime.isAfter(returnDateTime)) {
    booking.status = BookingStatus.completed;
  }
  return booking;
};

export const cancelBooking = async (bookingId: string) => {
  try {
    const bookingRef = doc(db, 'bookings', bookingId);
    await updateDoc(bookingRef, {
      status: BookingStatus.cancelled
    });
    return { success: true, message: 'Bookings updated successfuly'};
  } catch (error) {
    console.error('Failed to update status:', error);
  }
};

// export const updateRetunInfo = async (bookingId: string, returnCode: number | string) => {
//   try {
//     const bookingRef = doc(db, 'bookings', bookingId);
//     await updateDoc(bookingRef, {
//       pickupStatus: PickupReturnStatus.returned,
//       status: BookingStatus.completed,
//       return: returnCode
//     });
//     return { success: true, message: 'Bookings updated successfuly'};
//   } catch (error) {
//     console.error('Failed to update status:', error);
//   }
// };

export const updatePickupInfo = async (
    bookingId: string,
    pickupComments: string,
    pickupPhotos: Photo[],
    pickupDamage: boolean
  ): Promise<any> => {

     // Upload files and get download URLs
    const uploadedPhotos = await Promise.all(pickupPhotos.map(async (photo) => {
      const storageRef = ref(storage, `bookings/pickupPhotos/${bookingId}/${photo.file.name}`);
      const snapshot = await uploadBytes(storageRef, photo.file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return {
        url: downloadURL,
      };
    }));    

    const pickupDetails = {
      existingDamage: pickupDamage,
      comment: pickupComments,
      pictures: uploadedPhotos,
    }

    try {
      const bookingRef = doc(db, 'bookings', bookingId);
      await updateDoc(bookingRef, {
        pickup: pickupDetails,
        pickupStatus: PickupReturnStatus.pickedUp,
        status: BookingStatus.active
      });
      return { success: true, message: 'Bookings updated successfuly'};
    } catch (error) {
      console.error('Failed to update status:', error);
    }
}

export const getPickupPictures = async (bookingID: string): Promise<ServiceResponse> => {
  try {
    const listRef = ref(storage, `bookings/pickupPhotos/${bookingID}/`);
    const res = await listAll(listRef);
    const urls = await Promise.all(res.items.map(itemRef => getDownloadURL(itemRef)));    
    if (urls) {
      return { success: true, message: 'Bookings successfuly',  data: urls};
    } else {
      return { success: false, message: "No such item" };
    }

  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
