import React from 'react';
import { Grid, } from '@mui/material';

interface GridProps {
  children?: any
}

  const gridContainerStyle = { 
  mt: '10px',
  padding: '24px',
  direction:"row",
  alignItems:"center",
  justifyContent: "center",
  gap: 2,
}


export const GridContainer: React.FC<GridProps> = ({ children}) => {
  return (
    <Grid container sx={{ ...gridContainerStyle}}>
      { children }
    </Grid>
  )
}

export const GridItem: React.FC<GridProps> = ({ children}) => {
  return (
    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
      { children }
    </Grid>
  )
}
