import React, { useEffect, useState } from 'react';
import { Button, CardContent, Typography, TextField, Box, Card, IconButton } from '@mui/material';
import { GridContainer, GridItem } from '../../components/cards/Grid';
import { getUser, logout } from '../../services/AuthService';
import { SuccessError } from '../../hooks/SuccessError';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useTitle } from '../../hooks/TitleProvider';


export const Account: React.FC = () => {

  const [contactDetails, setContactDetails] = useState({
    email: '',
    password: '*********',
  });
  const [successErrorOpen, setSuccessErrorOpen] = useState(false);
  const [successErrorSeverity, setSuccessErrorSeverity] =useState<any>('');
  const [successErrorMessage, setSuccessErrorMessage] = useState('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const toggleEdit = () => setIsEditing(!isEditing);
  const navigate = useNavigate();
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Account");
    const checkUserEmail = async () => {
      const response = await getUser();
      if (response.success) {
        setContactDetails((prevDetails) => ({
          ...prevDetails,
          email: response.data.email,
        }));
      }
    };
    checkUserEmail();
  }, []);

  const saveDetails = () => {
    setIsEditing(false);
  };


  const Logout = async() => {
    try {
      const response = await logout();
      navigate('/');
      setSuccessErrorOpen(true)
      setSuccessErrorSeverity('success')
      setSuccessErrorMessage(response.message)
    } catch (error: any) {
      setSuccessErrorOpen(true)
      setSuccessErrorSeverity('error')
      setSuccessErrorMessage(error.message)
    }
  };

  return (
    <GridContainer>
      <GridItem>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">Contact Details</Typography>
              <IconButton onClick={isEditing ? saveDetails : toggleEdit} sx={{ marginLeft: 2 }}>
                {isEditing ? <SaveIcon color="primary" /> : <EditIcon />}
              </IconButton>
            </Box>
            <TextField
              label="Email"
              fullWidth
              disabled
              margin="normal"
              name="email"
              value={contactDetails.email}
            />
            <TextField
              label="Password"
              fullWidth
              disabled
              margin="normal"
              name="password"
              type="password"
              value={contactDetails.password}
            />

          </CardContent>
        </Card>
      </GridItem>
      <GridItem>
        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', mt: 2 }}>
          <Button variant="outlined" onClick={Logout} color="error">Log out</Button>
        </Box>
      </GridItem>
      <SuccessError open={successErrorOpen} severity={successErrorSeverity} message={successErrorMessage} />
    </GridContainer>
  );
};
