import React from 'react';
import { Box, Typography } from '@mui/material';

export const Footer: React.FC = () => {
  return (
    <Box sx={{ py: 3, textAlign: 'center', borderTop: '1px solid #e0e0e0', mt: 4 }}>
       <Typography variant="body2" color="textSecondary">
           &copy; {new Date().getFullYear()} boro. All rights reserved.
      </Typography>
    </Box>
  );
};
