
import { collection, addDoc, getDocs, query, where, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Location, ServiceResponse } from '../types';



interface Filters {
    lat?: number;
    lng?: number;
    radius?: number;
} 

export const getAllSurfboards = async (): Promise<ServiceResponse> => {
    try {
      const surfboardsCollection = collection(db, 'surfboards');
      const surfboardsSnapshot = await getDocs(surfboardsCollection);
      return { success: true, message: 'Bookings successfuly',  data: surfboardsSnapshot.docs };

    } catch (error: any) {
      return { success: false, message: error.message };
    }
};

export const createSurfboards = async (data: any): Promise<ServiceResponse> => {
  try {
    await addDoc(collection(db, "surfboards"), data);
    return { success: true, message: 'Request received'};
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const toRadians = (degrees: number) => degrees * (Math.PI / 180);

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};



