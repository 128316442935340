import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Container, Box, Paper, Typography, Dialog, Button, Checkbox, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Surfboard, Booking, PaymentStatus, BookingStatus, addOn, PickupReturnStatus} from '../../types';
import { createBooking } from '../../services/BookingsService';
import { DateTimeRange } from '../cards/DateTimeRange';
import { SuccessError } from '../../hooks/SuccessError';
import { useNavigate } from 'react-router-dom';
import { StripeCheckoutForm } from '../payments/stripeCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { createPaymentIntent } from '../../services/Payments';

const stripePromise = loadStripe('pk_test_51OgEX1L3mPMyAQMGD03VDJNpA0GAgc5qx83bSLRdJbRmCG5nX62tm64ZzzNHRGYRzmPmxlWbjw9rFDyK4CmjAWp400DSyYhh8H');

interface BookProps {
  userID: string;
  surfboard: Surfboard;
  open: boolean;
  onClose: () => void;
}

interface DateTimeRangeState {
  pickupDateTime: dayjs.Dayjs;
  returnDateTime: dayjs.Dayjs;
}

export const Book: React.FC<BookProps> = ({ userID, surfboard, open, onClose }) => {
  const navigate = useNavigate();
  const roundToNearest30Minutes = (date: dayjs.Dayjs) => {
    const remainder = 30 - (date.minute() % 30);
    return date.add(remainder, 'minute').startOf('minute');
  };

  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRangeState>({
    pickupDateTime: roundToNearest30Minutes(dayjs()).add(2, 'hour'),
    returnDateTime: roundToNearest30Minutes(dayjs()).add(3, 'hour'),
  });

  const currentTimestamp = new Date().getTime();
 
  const [booking, setBooking] = useState<Booking>({
    id: '',
    addOns: [],
    addOnsCost: 0,
    amountDays: 0,
    amountHours: 0,
    itemCost: 0,
    totalBookingCost: 0,
    paymentStatus: PaymentStatus.paid,
    refCode: Math.floor(1000 + Math.random() * 9000),
    pickupDateTime: dateTimeRange.pickupDateTime.toISOString(),
    returnDateTime: dateTimeRange.returnDateTime.toISOString(),
    status: BookingStatus.upcoming,
    pickupStatus: PickupReturnStatus.pending,
    surfboardID: surfboard.id,
    userID: userID,
    timeStamp: currentTimestamp,
    pickup: null,
    return: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [successErrorOpen, setSuccessErrorOpen] = useState(false);
  const [successErrorSeverity, setSuccessErrorSeverity] = useState<'success' | 'error'>('success');
  const [successErrorMessage, setSuccessErrorMessage] = useState('');
  const [selectedAddOns, setSelectedAddOns] = useState<any[]>(new Array(surfboard.addOns.length).fill(null));
  const [clientSecret, setClientSecret] = useState<string | null>('pi_1JU1nY2eZvKYlo2ChA2K3RbS_secret_HxP3D1KNvhs8I9gQf4H7YvJ2D');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [handleSubmit, setHandleSubmit] = useState<(() => Promise<void>) | null>(null);



  const stripeOptions: StripeElementsOptions = {
    clientSecret: clientSecret as string, // Ensure this is always a string
    appearance: {
      theme: 'flat',
    },
  };


  const handleBookNowClick = async () => {
    if (handleSubmit) {
      await handleSubmit();
    } else {
      console.error("Stripe not initialized yet.");
    }
    setLoading(true);
    const updatedBooking = {
      ...booking,
      pickupDateTime: dateTimeRange.pickupDateTime.toISOString(),
      returnDateTime: dateTimeRange.returnDateTime.toISOString(),
    };
    setBooking(updatedBooking);  
    try {
      await createBooking(updatedBooking)
      setSuccessErrorSeverity('success');
      setSuccessErrorMessage("Rental request sent successfully! We'll come back to you within 24 hours.");
      setSuccessErrorOpen(true);
      navigate('/bookings')
      onClose();
    } catch (error) {
      console.error('Error creating item:', error);
      setSuccessErrorSeverity('error');
      setSuccessErrorMessage('Failed to send request. Please try again.');
      setSuccessErrorOpen(true);
    } finally {
      setLoading(false);
    } 
 
  };

  useEffect(() => {
    calculateCosts();
    // const fetchBookingAndPaymentIntent = async () => {
    //   try {
    //     const paymentIntentResponse: any = await createPaymentIntent(booking.totalBookingCost);
    //     setClientSecret(paymentIntentResponse?.clientSecret);

    //   } catch (error) {
    //     console.error('Failed to stripe:', error);
    //     setErrorMessage('Failed to stripe.');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchBookingAndPaymentIntent();
  }, [dateTimeRange]);


  const handleAddOns = (addOn: addOn, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
   
    if(event.target.checked){
      selectedAddOns[index] = addOn; 
    } else {
      selectedAddOns[index] = null;
    }   
    const days = dateTimeRange.returnDateTime.diff(dateTimeRange.pickupDateTime, 'day');
    var addOnsCost = 0;
    var newTotal = 0;
    if (days === 0) {
      addOnsCost = event.target.checked? addOn.priceDay : -addOn.priceDay
      newTotal = booking.totalBookingCost + addOnsCost
    } else {
      addOnsCost = event.target.checked? days*addOn.priceDay : -days*addOn.priceDay
      newTotal = booking.totalBookingCost + addOnsCost
    }
    if (newTotal < booking.itemCost ) {
      newTotal = booking.itemCost 
    } 
    setBooking(prev => ({ ...prev, addOns: selectedAddOns, addOnsCost: addOnsCost,  totalBookingCost: newTotal}));
  };


  const calculateCosts = () => {
    const days = dateTimeRange.returnDateTime.diff(dateTimeRange.pickupDateTime, 'day');
    const hours = dateTimeRange.returnDateTime.diff(dateTimeRange.pickupDateTime, 'hour');
    var calcHours = 0
    if (hours < 24 ){
      calcHours = hours
    }
    var price = 0;
    var newTotal = 0;   

    if (days === 0) {      
      price = hours * surfboard.priceHour
      newTotal = price + booking.addOnsCost
    } else {
      price = days * surfboard.priceDay
      newTotal = price + booking.addOnsCost
    }
    if (newTotal < booking.itemCost ) {
      newTotal = booking.itemCost 
    }
    setBooking(prev => ({ ...prev, amountDays: days, amountHours: calcHours, itemCost: price, totalBookingCost: newTotal }));
    
  };

  return (
      <>
      <Dialog open={open} onClose={onClose} fullWidth>
          {loading ? (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Container maxWidth="md">
                 <Box mt={4}>
                  <Typography gutterBottom variant="h4" component="div" align="center" color="primary">
                    Booking
                  </Typography>
                 </Box>

                <Box mt={4}>
                  <Paper elevation={3}>
                    <Box p={2}>
                      <DateTimeRange dateTimeRange={dateTimeRange} setDateTimeRange={setDateTimeRange} />
                    </Box>
                  </Paper>
                </Box>
                
                <Box mt={4}>      
                  <Paper elevation={3}>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <img src={surfboard.thumbnail} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={9} >
                          <Typography variant="h6">{surfboard.title}</Typography>
                          <Typography sx={{ fontSize: '9px' }} >{surfboard.location.name}</Typography>
                          <Typography color="primary">available</Typography>
                          {(booking.amountHours !== 0 || booking.amountDays !== 0) && (
                              <Typography variant="body1">
                                {`${booking.amountHours !== 0 ? 'Hour(s)' : 'Day(s)'} Booked:  `}
                                {`${booking.amountHours !== 0 ? booking.amountHours : booking.amountDays} x $${booking.amountHours !== 0 ? 
                                  surfboard.priceHour : surfboard.priceDay} = $${booking.itemCost}`}
                              </Typography>
     
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Box>

                <Box mt={4}>
                  <Typography variant="h6">Additional service(s)</Typography>
                  <Paper elevation={3}>
                    {surfboard.addOns.map((addOn: addOn, index: number) => (
                        <Box p={2} key={index}>
                           <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                               <Box display="flex" alignItems="center">
                                  <Checkbox onChange={(e) => handleAddOns(addOn, index, e)} />
                                  <Typography>{addOn.name}</Typography>
                               </Box>
                               <Typography>{booking.amountDays === 0 ? 1 :booking.amountDays } Day(s) x ${addOn.priceDay} = ${(booking.amountDays === 0 ? 1 :booking.amountDays) * addOn.priceDay }</Typography>
                           </Box>
                        </Box>
                    ))}
                  </Paper>
                </Box>


                <Box mt={4} display="flex" justifyContent="space-between">
                  <Typography variant="h6">Subtotal</Typography>
                  <Typography variant="h6">
                       $ {booking.totalBookingCost}
                  </Typography>
                </Box>

                <Box mt={4}>
                  <Typography variant="h6">Payments</Typography>
                  <Paper elevation={3}>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                    <StripeCheckoutForm
                      clientSecret={clientSecret}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      onReadyToPay={setHandleSubmit}
                    />
                    </Elements>
                  </Paper>
                </Box>

                <Stack my={4} mt={4} display="flex" justifyContent="center" alignItems="center">
                  <Button onClick={handleBookNowClick} variant="contained" color="primary" fullWidth sx={{ color: 'white', textTransform: 'none' }}>Checkout</Button>
                </Stack>
            </Container>
          )}

      </Dialog>

      <SuccessError open={successErrorOpen} severity={successErrorSeverity} message={successErrorMessage} />

      </>
  );
};
