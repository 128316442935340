import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Box, Button, Typography } from '@mui/material';

interface StripeCheckoutFormProps {
  clientSecret: string | null;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
  onReadyToPay: (submitFunction: () => Promise<void>) => void;
}

export const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = ({
  clientSecret,
  errorMessage,
  setErrorMessage,
  onReadyToPay
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret!, {
      payment_method: {
        card: cardElement,
        billing_details: {
          // Add billing details here
        },
      },
    });

    if (error && error.message) {
      setErrorMessage(error.message);
    } else if (paymentIntent?.status === 'succeeded') {
      // Handle successful payment here
      console.log('Payment succeeded');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, p:2}}>
      <CardElement />
      {errorMessage && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

