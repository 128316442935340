
import { Message, ServiceResponse } from '../types';
import { collection, addDoc} from 'firebase/firestore';
import { db } from '../firebase';


export const createListRequest = async (data: Message): Promise<ServiceResponse> => {
    try {
      await addDoc(collection(db, "listRequests"), data);
      return { success: true, message: 'Request received'};
    } catch (error: any) {
      return { success: false, message: error.message };
    }
};

export const createMessage = async (data: Message): Promise<ServiceResponse> => {
    try {
      await addDoc(collection(db, "message"), data);
      return { success: true, message: 'Request received'};
    } catch (error: any) {
      return { success: false, message: error.message };
    }
};


