import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

interface SuccessErrorProps {
  open: boolean
  severity?: string;
  message: string;
}

export const SuccessError: React.FC<SuccessErrorProps> = ({ open, severity, message }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<any>(severity);
  const [snackbarMessage, setSnackbarMessage] = useState(message);

  useEffect(() => {
    setSnackbarOpen(open);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  }, [open, severity, message]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export const SuccessSnackbar: React.FC<SuccessErrorProps> = ({ open, message }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(message);

  useEffect(() => {
    setSnackbarOpen(open);
    setSnackbarMessage(message);
  }, [open, message]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity='success'
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export const ErrorSnackbar: React.FC<SuccessErrorProps> = ({ open, message }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setSnackbarOpen(open);
  }, [open]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity='error'
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};